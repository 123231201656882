// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import booleanVar from './booleanVar'
import docFiscale from './docFiscale'
import onBoarding from './onBoarding'
import appEmailSlice from '../views/apps/email/store/index'
const rootReducer = {
  auth,
  navbar,
  layout,
  booleanVar,
  docFiscale,
  onBoarding,
  appEmailSlice
}

export default rootReducer
