import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
    currentStep: 0,
    currentSubStep: null,
    steps: [],
    completionDate: null,
    isOnboardingComplete: false
}

const onboardingSlice = createSlice({
    name: 'onBoarding',
    initialState,
    reducers: {
        SET_STEPS: (state, action) => {
            state.steps = action.payload
            state.currentStep = action.payload.findIndex(step => !step.completed)
            state.currentSubStep = null
            state.isOnboardingComplete = state.steps.every(step => step.completed)
        },
        NEXT_STEP: (state) => {
            if (state.currentStep < state.steps.length - 1) {
                state.steps[state.currentStep].completed = true
                state.currentStep += 1
                state.currentSubStep = null // Resetta i sottosteps quando si passa allo step successivo
            } else {
                state.completionDate = moment().format('YYYY-MM-DD')
                state.isOnboardingComplete = true
            }
        },
        PREV_STEP: (state) => {
            if (state.currentStep > 0) {
                state.steps[state.currentStep - 1].completed = false
                state.currentStep -= 1
                state.currentSubStep = null // Resetta i sottosteps quando si torna indietro di step
            }
        },
        NEXT_SUB_STEP: (state) => {
            const currentMainStep = state.steps[state.currentStep]

            if (currentMainStep.steps && currentMainStep.steps.length > 0) {
                if (state.currentSubStep === null) {
                    state.currentSubStep = 0 // Inizia dal primo sottostep
                } else if (state.currentSubStep < currentMainStep.steps.length - 1) {
                    currentMainStep.steps[state.currentSubStep].completed = true
                    state.currentSubStep += 1
                } else {
                    currentMainStep.steps[state.currentSubStep].completed = true
                    state.currentSubStep = null
                    state.currentStep += 1
                }
            }
        },
        PREV_SUB_STEP: (state) => {
            const currentMainStep = state.steps[state.currentStep]

            if (currentMainStep.steps && currentMainStep.steps.length > 0) {
                if (state.currentSubStep > 0) {
                    currentMainStep.steps[state.currentSubStep].completed = false
                    state.currentSubStep -= 1
                } else if (state.currentSubStep === 0) {
                    state.currentSubStep = null // Se siamo al primo sottostep, torna al livello principale
                } else {
                    if (state.currentStep > 0) {
                        state.currentStep -= 1
                        const previousMainStep = state.steps[state.currentStep]
                        if (previousMainStep.steps && previousMainStep.steps.length > 0) {
                            state.currentSubStep = previousMainStep.steps.length - 1
                        }
                    }
                }
            }
        },
        RESET_ONBOARDING: (state) => {
            state.currentStep = 0
            state.currentSubStep = null
            state.steps = state.steps.map(step => ({
                ...step,
                completed: false,
                steps: step.steps ? step.steps.map(subStep => ({ ...subStep, completed: false })) : []
            }))
            state.completionDate = null
            state.isOnboardingComplete = false
        }
    }
})

export const { SET_STEPS, NEXT_STEP, PREV_STEP, NEXT_SUB_STEP, PREV_SUB_STEP, RESET_ONBOARDING } = onboardingSlice.actions

export default onboardingSlice.reducer
