import axios from 'axios'
import toast from 'react-hot-toast'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
    headers: {
        'Content-Type': 'application/json'
    }
})

// Adding a request interceptor
axiosInstance.interceptors.request.use(
    config => {
        // Retrieve the token and apiOrganizzazione from localStorage
        const token = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).token : null
        const organization = localStorage.getItem('activeAgency') ? JSON.parse(localStorage.getItem('activeAgency')).apiOrganizzazione : null
        const puntoVendita = localStorage.getItem('activePuntoVendita') ? JSON.parse(localStorage.getItem('activePuntoVendita')).id : null
        const language = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'

        if (token) {
            config.headers['Authorization'] = token
        }

        if (organization) {
            config.headers['api-organizzazione'] = `${organization}`
        }
        if (puntoVendita) {
            config.headers['punto-vendita-id'] = `${puntoVendita}`
        }
        if (language) {
            config.headers['Accept-Language'] = `${language}`
        }

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    response => {
        // Check if the response has a message and display it
        if (response.config.showSuccessToast !== false) {
            const message = response.data.message || "Operazione andata a buon fine"
            toast.success(message, {
                position: "top-right",
                duration: 3000,
                style: {
                    background: 'green',
                    color: 'white'
                }
            })
        }
        return response
    },
    error => {
        // Attempt to extract a more specific error message from the server's response
        if (error.config.showErrorToast !== false) {
            const errorMessage = error.response?.data?.message || error.message || "An error occurred"

            // Check if there are specific errors in the response
            const errors = error.response?.data?.errors
            if (errors) {
                Object.keys(errors).forEach(key => {
                    errors[key].forEach(errorDetail => {
                        toast.error(errorDetail, {
                            position: "top-right",
                            duration: 3000,
                            style: {
                                background: 'red',
                                color: 'white'
                            }
                        })
                    })
                })
            } else {
                toast.error(errorMessage, {
                    position: "top-right",
                    duration: 3000,
                    style: {
                        background: 'red',
                        color: 'white'
                    }
                })
            }
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
